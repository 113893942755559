import { useT } from '@/utils/language'
import { useCallback } from 'react'

type ErrorCode =
  | 'already_applied_charge_stock'
  | 'incorrect_coupon_code'
  | 'already_applied'
  | 'not_found' // Stand
  | 'not_finished' // Settlement
  | 'limit_over' // Rental
  | 'spot_payment_not_bind' // SpotAppPayment
  | 'login_failed' // Login

export type FieldError = {
  code: ErrorCode
  field: string
  resource: string
}

export type ResponseError = {
  errors?: FieldError[]
  message: string
}

export function useGetErrorMessage() {
  const T = useT()

  return useCallback(
    (e: ResponseError) => {
      const code = e.errors?.find((e: FieldError) => e.code)?.code || null

      // plan
      if (code === 'already_applied_charge_stock') {
        return T('errorAlreadyAppliedChargeStock')
      }

      // coupon
      if (code === 'incorrect_coupon_code') {
        return T('errorIncorrectCouponCode')
      }
      if (code === 'already_applied') {
        return T('errorAlreadyApplied')
      }

      // login
      if (code === 'login_failed') {
        return T('errorLoginFailed')
      }

      // backend message
      if (e.message) {
        return e.message
      }

      return T('errorUnexpected')
    },
    [T]
  )
}
