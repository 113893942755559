import { useCallback, useEffect, useState } from 'react'
import { format as formatDate } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { useT } from '@/utils/language'
import { useRequest } from '@/utils/request'
import { useIkasaId } from '@/utils/setting'
import { Kasa } from '@/utils/kasa'
import { useToast } from '@/utils/toast'

import Page from '@/components/Page'
import Icon from '@/components/Icon'

import KasaLostModal from './KasaLostModal'

import styles from './KasaLostPage.module.css'

function KasaLostPage() {
  const T = useT()
  const ikasaId = useIkasaId()
  const request = useRequest()
  const navigate = useNavigate()

  const [rentingKasaList, setRentingKasaList] = useState<Kasa[]>([])
  const getRentingKasaList = useCallback(async () => {
    const kasaList = await request<Kasa[]>(
      `/v4/rental/count/${ikasaId}`
    )
    setRentingKasaList(kasaList)
  }, [request, ikasaId])
  useEffect(() => {
    getRentingKasaList()
  }, [getRentingKasaList])

  const [kasaBought, setKasaBought] = useState(false)
  const [lostingKasa, setLostingKasa] = useState<Kasa | null>(null)
  const toast = useToast()
  const submitLostKasa = useCallback(
    async (kasa: Kasa) => {
      await request('/v4/purchase', {
        method: 'POST',
        showToast: false,
        data: { ikasaId, ksid: kasa.ksid },
      }).catch(() => {
        // universal error
        toast(T('kasaLostError'), 'error')
        return Promise.reject()
      })
      setKasaBought(true)
    },
    [request, ikasaId, T, toast]
  )

  if (kasaBought) {
    return (
      <Page className={styles.page} title={T('kasaLostApply')}>
        <div className={styles.title}>{T('kasaLostSuccess')}</div>
        <div className={styles.successText}>{T('kasaLostSuccessHint')}</div>
        <button
          className={styles.profileButton}
          onClick={() => {
            navigate('/profile')
          }}
        >
          {T('myPage')}
        </button>
      </Page>
    )
  }

  return (
    <Page className={styles.page} title={T('kasaLostApply')}>
      <div className={styles.hint}>{T('kasaLostHint')}</div>
      <div className={styles.price}>{T('kasaLostPrice')}</div>

      {rentingKasaList.length === 0 && (
        <div className={styles.noRenting}>{T('noRentingKasa')}</div>
      )}

      {rentingKasaList.map((kasa) => (
        <div
          key={kasa.ksid}
          className={styles.kasa}
          onClick={() => {
            setLostingKasa(kasa)
          }}
        >
          <div className={styles.date}>
            {formatDate(new Date(kasa.rentedAt || ''), T('rentedAtFormat'))}
          </div>

          <div className={styles.info}>
            <div className={styles.id}>ID:{kasa.ksid}</div>
            <div className={styles.pin}>
              <Icon className={styles.pinIcon} name="lock" size={10} />
              {kasa.rentedScd}
            </div>
          </div>

          <Icon className={styles.rightArrow} name="chevronRight" />
        </div>
      ))}

      {lostingKasa && (
        <KasaLostModal
          kasa={lostingKasa}
          onSubmit={submitLostKasa}
          onCancel={() => {
            setLostingKasa(null)
          }}
        />
      )}
    </Page>
  )
}

export default KasaLostPage
