import { login } from '@chargespot/spjs'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { useCallback, useEffect } from 'react'

import { useFirebaseConfig } from '@/utils/env'
import { useRequest } from '@/utils/request'
import { getStorageItem, setStorageItem } from './storage'

declare global {
  interface Window {
    setIkasaId: (ikasaId: string) => void
  }
}

export function useLogin() {
  const request = useRequest()
  const firebaseConfig = useFirebaseConfig()

  useEffect(() => {
    initializeApp(firebaseConfig)
  }, [firebaseConfig])

  // HACK: inject ikasaId setter
  useEffect(() => {
    window.setIkasaId = (ikasaId: string) => {
      setStorageItem<string>('ikasaId', ikasaId)
    }
  }, [])

  return useCallback(async () => {
    // developement ikasa id
    const storageIkasaId = getStorageItem<string>('ikasaId')
    if (storageIkasaId) {
      return { ikasaId: storageIkasaId }
    }

    const { code } = await login()
    console.log('ログイン: code', code)
    let res = await request<{ customToken: string }>(
      '/v4/share-spot/spot_custom_token',
      { showLoading: false, data: { code } }
    )

    // レスポンスの型をログに出力して確認
    console.log('カスタムトークンレスポンス:', res)

    // resがResponseオブジェクトの場合はJSONに変換
    let tokenData;
    if (res instanceof Response) {
      tokenData = await res.json();
      console.log('パースされたトークンデータ:', tokenData);
    } else {
      tokenData = res;
    }

    const auth = getAuth()
    const { user } = await signInWithCustomToken(auth, tokenData.customToken)

    // TODO: maybe save the ikasaId into storage

    return { ikasaId: user.uid }
  }, [request])
}
