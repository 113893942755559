import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

import { Spot } from '@/utils/spot'
import { useT } from '@/utils/language'
import { useRequest } from '@/utils/request'

import Icon from '@/components/Icon'

import styles from './IndexSpotCard.module.css'

type IndexSpotCardProps = {
  className?: string
  spot: Spot
  onClose: () => void
}

function IndexSpotCard(props: IndexSpotCardProps) {
  const T = useT()
  const navigate = useNavigate()
  const request = useRequest()
  const { className, spot, onClose } = props
  const {
    spotImg,
    spotName,
    shareStartTime,
    shareEndTime,
    holiday,
    isMaintenance,
    kasaMax,
  } = spot

  const [count, setCount] = useState(0)
  const getSpotStockCount = useCallback(async () => {
    const res = await request<{ count: number }>(
      // MeillTODO: /v4/spots/:id/stocksのidはscdが入る想定なのか確認したい
      `/v4/spots/${spot.scd}/stocks`
    )
    setCount(res.count)
  }, [request, spot.scd])
  useEffect(() => {
    getSpotStockCount()
  }, [getSpotStockCount])

  return (
    <div
      className={cx(styles.container, className)}
      onClick={() => {
        navigate(`/spot/${spot.scd}`)
      }}
    >
      <div className={styles.header}>
        <img className={styles.headerImage} src={spotImg} alt="" />
        <div
          className={cx(styles.headerImageMask, {
            [styles.headerImageMaskRed]: isMaintenance,
          })}
        ></div>
        <div className={styles.name}>{spotName}</div>
        <div className={styles.text}>
          {shareStartTime}~{shareEndTime}
        </div>
        <div className={styles.text}>
          {T('holiday')}: {holiday}
        </div>

        <button
          className={styles.closeButton}
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
        >
          <Icon name="close" />
        </button>
      </div>

      <div className={styles.footer}>
        {!isMaintenance && (
          <div className={styles.count}>
            <Icon className={styles.countIcon} name="kasa" />
            <div className={styles.countHint}>{T('kasaCount')}</div>
            {count} / {kasaMax} {T('kasaUnit')}
          </div>
        )}

        {isMaintenance && (
          <div className={styles.mainenance}>
            <Icon className={styles.mainenanceIcon} name="spanner" />
            <div className={styles.mainenanceHintLarge}>
              {T('isMaintenance')}
            </div>
            <div className={styles.mainenanceHintSmall}>
              {T('maintenanceHint')}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default IndexSpotCard
