import { chooseCoupon, requestBindPayment } from '@chargespot/spjs'
import { useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useT } from '@/utils/language'
import { PlanType } from '@/utils/plan'
import { useRequest } from '@/utils/request'
import { useIkasaId } from '@/utils/setting'
import { useToast } from '@/utils/toast'
import { useUser } from '@/utils/user'


import Page from '@/components/Page'
import PlanPriceCard from '@/components/PlanPriceCard'

import DOMPurify from 'dompurify'
import styles from './ProfilePlanChangePage.module.css'

type PlanConfig = {
  title: string
  introduction: string
  cautiousPoint: string
}

function ProfilePlanChangePage() {
  const T = useT()
  const user = useUser()
  const [searchParams] = useSearchParams()
  const nextPlanType = searchParams.get('next') as PlanType
  const previousPlanType = user?.planType

  const nextPlan = useMemo<PlanConfig | null>(() => {
    if (nextPlanType === PlanType.Subscription) {
      return {
        title: T('subscriptionPlan'),
        introduction: T('subscriptionPlanIntroduction'),
        cautiousPoint: T('subscriptionPlanCautious'),
      }
    }

    if (nextPlanType === PlanType.Normal) {
      return {
        title: T('normalPlan'),
        introduction: T('normalPlanIntroduction'),
        cautiousPoint: T('normalPlanCautious'),
      }
    }

    return null
  }, [T, nextPlanType])

  const request = useRequest()
  const ikasaId = useIkasaId()
  const toast = useToast()
  const navigate = useNavigate()
  const onChangePlan = useCallback(async () => {
    // HACK: ikasa must change plan type enum string
    const previousPlan = previousPlanType === PlanType.Normal ? 'charge' : nextPlanType
    const nextPlan = nextPlanType === PlanType.Normal ? 'charge' : nextPlanType

    try {
      const { alreadyRegistered: isPaymentRegistered } = await request<{ alreadyRegistered: boolean }>(`/service/v1/payment/spot/confirm/${ikasaId}`, {
        method: 'GET',
      });

      if (!isPaymentRegistered) {
        try {
          await requestBindPayment()
        } catch (e: any) {
          if (e.errCode !== -1) toast(e.errMsg)
          return Promise.reject(e)
        }
      }
    } catch (e) {
      return Promise.reject(e)
    }

    if (previousPlan === 'charge' && nextPlan === PlanType.Subscription) {
      try {
        await new Promise(res => setTimeout(res, 1000));
        const chooseCouponPayload = await chooseCoupon()
        const coupon = chooseCouponPayload?.couponId
        if (coupon) {
          await request('/service/v1/campaign/coupon/spot', {
            method: 'POST',
            data: { ikasaId, couponId: coupon },
          })
        }
      } catch (e: any) {
        if (e.errCode !== -1) {
          toast(e.errMsg)
        }
        return Promise.reject(e)
      }
    }

    try {
      await request('/v4/plan/update', {
        method: 'POST',
        data: { ikasaId, planType: nextPlan },
      });
    } catch (e) {
      return Promise.reject(e)
    }

    let successMessage
    if (nextPlanType === PlanType.Subscription) {
      successMessage = T('changeToSubscriptionPlanSuccess')
    }

    if (nextPlanType === PlanType.Normal) {
      successMessage =
        previousPlanType === PlanType.Office
          ? T('changeToNormalPlanSuccess')
          : T('changeToNormalPlanAppliedSuccess')
    }

    if (nextPlanType === PlanType.Office) {
      successMessage = T('changeToOfficePlanSuccess')
    }

    if (successMessage) {
      toast(successMessage, 'success')
    }

    navigate('/profile/plan')
  }, [nextPlanType, previousPlanType, ikasaId, request, toast, T, navigate])

  if (!nextPlan) return null

  const { title, introduction, cautiousPoint } = nextPlan

  return (
    <Page className={styles.page} title={title}>
      <div className={styles.title}>{T('planIntroduction')}</div>
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(introduction) }} />
      <PlanPriceCard className={styles.priceCard} type={nextPlanType} />
      <div className={styles.title}>{T('cautiousPoint')}</div>
      <div className={styles.text}>{cautiousPoint}</div>

      <button className={styles.changeButton} onClick={onChangePlan}>
        {T('changeToThisPlan')}
      </button>
    </Page>
  )
}

export default ProfilePlanChangePage
