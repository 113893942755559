import cx from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  closeBLEConnection,
  closeBluetoothAdapter,
  createBLEConnection,
  getBLEDeviceServices,
  offBluetoothDeviceFound,
  onBluetoothDeviceFound,
  openBluetoothAdapter,
  startBluetoothDevicesDiscovery,
  stopBluetoothDevicesDiscovery,
} from '@chargespot/spjs'

import { Action } from '@/utils/action'
import {
  findWriteNotifyService,
  getKasaBattery,
  rentalKasa,
  returnKasa,
} from '@/utils/bluetooth'
import { useConfirm } from '@/utils/confirm'
import { ShareRequestPayload } from '@/utils/kasa'
import { useT } from '@/utils/language'
import { getProcessId, updateProcessId } from '@/utils/process'
import { useRequest } from '@/utils/request'
import { useIkasaId } from '@/utils/setting'
import { clearStorage } from '@/utils/storage'

import Icon from '@/components/Icon'
import Page from '@/components/Page'

import UnlockCompleteUI, { CompleteUI } from './UnlockCompleteUI'
import UnlockError from './UnlockError'
import UnlockGuideError from './UnlockGuideError'

import RentalHintImage from './images/rental-hint.png'
import ReturnHintImage from './images/return-hint.png'

import styles from './UnlockPage.module.css'

type OnBluetoothDeviceFound = Parameters<typeof onBluetoothDeviceFound>[0]
type Device = Parameters<OnBluetoothDeviceFound>[0]['devices'][0]

enum UnlockStep {
  GuideError = -4,
  ReadError = -3,
  TimeoutError = -2,
  UnknownError = -1,
  Starting = 0,
  Processing = 1,
  Unlocking = 2,
  Taking = 3,
  Complete = 4,
}

type UnlockStepItemProps = {
  index: number
  success: boolean
  name: string
  hint: string
  showHint: boolean
  showHintLine?: boolean
}

function UnlockStepItem(props: UnlockStepItemProps) {
  const { index, name, success, hint, showHint, showHintLine = true } = props
  return (
    <div className={styles.item}>
      <div className={styles.itemHeader}>
        <div
          className={cx(styles.itemRound, {
            [styles.itemRoundSuccess]: success,
          })}
        >
          {success ? <Icon name="check" size={20} /> : index}
        </div>
        <div className={styles.itemName}>{name}</div>
      </div>
      <div className={styles.itemBody}>
        <div className={styles.itemHintContainer}>
          {showHintLine && <div className={styles.itemHintLine}></div>}
        </div>
        {showHint && <div className={styles.itemHint}>{hint}</div>}
      </div>
    </div>
  )
}

type RentalActionResponse = {
  ikasaId: string
  ksid: number
  rentedScd: number
  pin: number
  transactionId: string
}

type ReturnActionResponse = {
  status: 'OK'
  ksid: number
  scd: number
}

function UnlockPage() {
  const T = useT()
  const { standId } = useParams<{ standId: string }>()
  const [searchParams] = useSearchParams()
  const ikasaId = useIkasaId()
  const request = useRequest()
  const confirm = useConfirm()
  const action = searchParams.get('action') as Action | null

  const navigate = useNavigate()
  const navigateToHome = useCallback(() => {
    navigate('/', { replace: true })
  }, [navigate])

  const [step, setStep] = useState<UnlockStep>(UnlockStep.Starting)
  const [completeUI, setCompleteUI] = useState<CompleteUI | null>(null)

  const deviceFoundTimeoutRef = useRef<number>()
  const unlockDevice = useCallback(
    async (device: Device) => {
      // URLパラメータからプロセスIDを取得、なければLocalStorageから取得
      const urlProcessId = searchParams.get('processId')
      let processId = urlProcessId || getProcessId()
      console.log('Using processId', processId)
      
      // プロセスIDをLocalStorageに保存（URLパラメータから取得した場合も保存）
      if (urlProcessId) {
        updateProcessId(urlProcessId)
      }
      // UnlockStep.Processingm
      // Connecting to Device
      setStep(UnlockStep.Processing)

      await createBLEConnection({ deviceId: device.deviceId })

      const { services } = await getBLEDeviceServices({
        deviceId: device.deviceId,
      })

      const { service, writeChar, notifyChar } = await findWriteNotifyService(
        device,
        services
      )
      if (!service || !writeChar || !notifyChar) return

      // UnlockStep.Unlocking
      // Get Device State
      setStep(UnlockStep.Unlocking)

      const standBattery = await getKasaBattery({
        deviceId: device.deviceId,
        serviceId: service.uuid,
        writeCharacteristicId: writeChar.uuid,
        notifyCharacteristicId: notifyChar.uuid,
      })

      // save ble state "wrote"
      await request(`/v4/kasa_in_out_histories`, {
        method: 'POST',
        data: {
          appVersion: 'SPOT mini App',
          ikasaId,
          processId,
          standId,
          readTimes: 2, // 初期値として2を設定
          state: 'wrote',
          when: action,
        },
      })

      // UnlockStep.Taking
      // Send Device Unlock Command
      window.clearTimeout(deviceFoundTimeoutRef.current)
      deviceFoundTimeoutRef.current = undefined
      setStep(UnlockStep.Taking)

      const actionFunction = action === 'rental' ? rentalKasa : returnKasa

      const { readTimes, tagId } = await actionFunction({
        deviceId: device.deviceId,
        serviceId: service.uuid,
        writeCharacteristicId: writeChar.uuid,
        notifyCharacteristicId: notifyChar.uuid,
      })

      await closeBLEConnection({ deviceId: device.deviceId })
      await closeBluetoothAdapter()

      // save ble state "closed"
      console.log("Saving ble state 'closed'")
      await request(`/v4/kasa_in_out_histories`, {
        method: 'POST',
        data: {
          appVersion: 'SPOT mini App',
          ikasaId,
          processId,
          standId,
          readTimes,
          tagId,
          state: 'closed',
          when: action,
        },
      })

      // no read
      if (readTimes === 0) {
        setStep(UnlockStep.ReadError)
        return
      }

      // complete UI
      // save action and get ui
      let completeUIResponse: CompleteUI | null = null
      // TODO: maybe chargespot
      const platform = ''

      // complete UI parameters
      let ksid: number | undefined

      // maybe unlocked
      if (readTimes === 1) {
        const { rental: lastRental, return: lastReturn } =
          await request<ShareRequestPayload>(
            `/v4/kasa_in_out_processes/${ikasaId}`
          )
        // using backend processId
        if (action === 'rental' && lastRental?.processId) {
          processId = lastRental.processId
          updateProcessId(processId)
        }
        if (action === 'return' && lastReturn?.processId) {
          processId = lastReturn.processId
          updateProcessId(processId)
        }

        const showConfirm =
          (action === 'rental' && lastRental) ||
          (action === 'return' && lastReturn)

        if (showConfirm) {
          const title =
            action === 'rental'
              ? T('rentalSuccessCheck')
              : T('returnSuccessCheck')

          const confirmResult = await confirm({
            title,
            cancel: T('failed'),
            confirm: T('succeed'),
          }).then(
            () => true,
            () => false
          )

          if (!confirmResult) {
            await request(`/v4/kasa_in_out_processes/${processId}`, {
              method: 'DELETE',
            })
            setStep(UnlockStep.GuideError)
            return
          }
        }

        // read times 2 ksid will be set after rental request
        ksid = action === 'rental' ? lastRental?.ksid : lastReturn?.ksid
      }

      // rental record request
      // rental complete ui
      if (action === 'rental') {
        let rentedScd: number | undefined
        let transactionId: string | undefined

        if (readTimes === 1) {
          const rentalActionResponse = await request<RentalActionResponse>(
            `/v4/rental/recovery`,
            {
              method: 'POST',
              data: { ikasaId, ksid, processId },
            }
          )
          // ksid was set before
          rentedScd = rentalActionResponse.rentedScd
          transactionId = rentalActionResponse.transactionId
        }

        if (readTimes === 2) {
          const rentalActionResponse = await request<RentalActionResponse>(
            `/v4/rental`,
            {
              method: 'POST',
              data: { appVersion: 'SPOT mini App', ikasaId, processId, readTimes, standBattery, standId, tagId },
            }
          )
          ksid = rentalActionResponse.ksid
          rentedScd = rentalActionResponse.rentedScd
          transactionId = rentalActionResponse.transactionId
        }

        completeUIResponse = await request<CompleteUI>(
          `/v4/rental/ui`,
          {
            method: 'POST',
            data: { ikasaId, platform, ksid, rentedScd, transactionId },
          }
        )
      }

      // return complete ui
      if (action === 'return') {
        let returnedScd: number | undefined

        if (readTimes === 1 && ksid !== undefined) {
          const returnActionResponse = await request<ReturnActionResponse>(
            `/v4/return/recovery`,
            {
              method: 'POST',
              data: { ikasaId, ksid, processId },
            }
          )
          // ksid was set before
          returnedScd = returnActionResponse.scd
        }

        if (readTimes === 2) {
          const returnActionResponse = await request<ReturnActionResponse>(
            `/v4/return`,
            {
              method: 'POST',
              data: { ikasaId, processId, readTimes, standBattery, standId, tagId },
            }
          )
          ksid = returnActionResponse.ksid
          returnedScd = returnActionResponse.scd
        }

        completeUIResponse = await request<CompleteUI>(
          `/v4/return/ui`,
          {
            method: 'POST',
            data: { ikasaId, platform, ksid, returnedScd },
          }
        )
      }

      // レンタル・返却完了時にLocalStorageをクリア
      clearStorage()
      console.log('LocalStorageをクリアしました')
      
      setStep(UnlockStep.Complete)
      setCompleteUI(completeUIResponse)
    },
    [T, ikasaId, standId, action, request, confirm, searchParams]
  )

  const onDeviceFound = useCallback<OnBluetoothDeviceFound>(
    async (payload) => {
      const device = payload.devices.find((device) => device.name === standId)
      if (!device) return

      await offBluetoothDeviceFound(onDeviceFound)
      await stopBluetoothDevicesDiscovery()
      unlockDevice(device).catch(() => {
        setStep(UnlockStep.UnknownError)
      })
    },
    [standId, unlockDevice]
  )

  const isUnlockingStep = useMemo(
    () =>
      [
        UnlockStep.Starting,
        UnlockStep.Processing,
        UnlockStep.Unlocking,
      ].includes(step),
    [step]
  )

  // UnlockStep.Starting
  // Finding Device Timeout
  const findDevice = useCallback(async () => {
    deviceFoundTimeoutRef.current = window.setTimeout(() => {
      window.clearTimeout(deviceFoundTimeoutRef.current)
      deviceFoundTimeoutRef.current = undefined
      if (isUnlockingStep) {
        setStep(UnlockStep.TimeoutError)
        closeBluetoothAdapter()
      }
    }, 20 * 100000)

    await openBluetoothAdapter()
    await startBluetoothDevicesDiscovery()

    onBluetoothDeviceFound(onDeviceFound)
  }, [onDeviceFound, isUnlockingStep])

  // UnlockStep.Starting
  // Finding Device
  useEffect(() => {
    if (step !== UnlockStep.Starting) return
    findDevice()
  }, [findDevice, step])

  // clean page
  useEffect(
    () => () => {
      closeBluetoothAdapter()
    },
    []
  )

  const unlockErrorType = useMemo(() => {
    if (step === UnlockStep.ReadError) return 'read'
    if (step === UnlockStep.TimeoutError) return 'timeout'
    if (step === UnlockStep.UnknownError) return 'unknown'
    return ''
  }, [step])

  const showCloseButton = useMemo(() => {
    if (step === UnlockStep.Taking) return false
    return true
  }, [step])

  if (!standId || !action) return null

  return (
    <Page>
      <div
        className={cx(styles.background, {
          [styles.backgroundWhite]: step === UnlockStep.GuideError,
        })}
      >
        {showCloseButton && (
          <button
            className={cx(styles.closeButton, {
              [styles.closeButtonWhite]: step === UnlockStep.GuideError,
            })}
            onClick={navigateToHome}
          >
            <Icon name="close" />
          </button>
        )}

        {/* guide error UI is very difference */}
        {step === UnlockStep.GuideError && (
          <UnlockGuideError
            action={action}
            onRetry={() => {
              setStep(UnlockStep.Starting)
            }}
          />
        )}

        {unlockErrorType && (
          <UnlockError
            type={unlockErrorType}
            onRetry={() => {
              setStep(UnlockStep.Starting)
            }}
          />
        )}

        {step === UnlockStep.Taking && (
          <div className={styles.takingContainer}>
            <div
              className={styles.takingHint}
              dangerouslySetInnerHTML={{ __html: T('unlockTakeHint') }}
            />
            <img
              src={action === 'rental' ? RentalHintImage : ReturnHintImage}
              alt=""
            />
          </div>
        )}

        {isUnlockingStep && (
          <div className={styles.container}>
            <div className={styles.title}>{T('pleaseWait')}</div>
            <UnlockStepItem
              index={1}
              success={step >= UnlockStep.Starting}
              name={T('connectStart')}
              hint={T('connectStartHint')}
              showHint={step === UnlockStep.Starting}
            />
            <UnlockStepItem
              index={2}
              success={step >= UnlockStep.Processing}
              name={T('processing')}
              hint={T('processingHint')}
              showHint={step === UnlockStep.Processing}
            />
            <UnlockStepItem
              index={3}
              success={step >= UnlockStep.Unlocking}
              name={T('unlock')}
              hint={T('unlockHint')}
              showHint={step === UnlockStep.Unlocking}
              showHintLine={false}
            />
            <button className={styles.cancelButton} onClick={navigateToHome}>
              {T('cancel')}
            </button>
          </div>
        )}

        {step === UnlockStep.Complete && completeUI !== null && (
          <UnlockCompleteUI
            action={action}
            ui={completeUI}
            onClose={navigateToHome}
          />
        )}
      </div>
    </Page>
  )
}

export default UnlockPage
