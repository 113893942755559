import { useEffect, useState } from 'react'
import cx from 'classnames'
import { format as formatDate } from 'date-fns'

import { useT } from '@/utils/language'
import { useRequest } from '@/utils/request'
import { UserHistory, HistoryItem } from '@/utils/user'
import { useIkasaId } from '@/utils/setting'

import Page from '@/components/Page'
import Icon from '@/components/Icon'

import styles from './ProfileHistoryPage.module.css'

type ProfileHistoryItemProps = {
  className?: string
  item: HistoryItem
}

function ProfileHistoryItem(props: ProfileHistoryItemProps) {
  const T = useT()
  const { className, item } = props
  const { stTime, stSpotName, enTime, enSpotName } = item

  const startDate = new Date(stTime)

  const using = !enTime

  return (
    <div
      className={cx(styles.historyItem, className, {
        [styles.historyItemUsing]: using,
      })}
    >
      <div className={styles.historyItemHeader}>
        <div className={styles.historyItemHeaderDate}>
          {formatDate(startDate, 'MM/dd')}
        </div>
        <div className={styles.historyItemHeaderText}>
          {using && T('using')}
        </div>
      </div>
      <div className={styles.historyItemBody}>
        <div className={styles.historyItemIconContainer}>
          <Icon name="beachAccess" />
        </div>

        <div className={styles.historyPosition}>
          <div className={styles.historyTime}>
            {formatDate(startDate, 'MM/dd HH:mm')}
          </div>
          <div className={styles.historyName}>{stSpotName}</div>
        </div>

        {enTime && (
          <Icon className={styles.historyPositionIcon} name="chevronRight" />
        )}

        {enTime && (
          <div
            className={cx(styles.historyPosition, styles.historyPositionEnd)}
          >
            <div className={styles.historyTime}>
              {formatDate(new Date(enTime), 'MM/dd HH:mm')}
            </div>
            <div className={styles.historyName}>{enSpotName}</div>
          </div>
        )}
      </div>
    </div>
  )
}

function ProfileHistoryPage() {
  const T = useT()

  const request = useRequest()
  const ikasaId = useIkasaId()
  const [userHistoryList, setUserHistoryList] = useState<UserHistory[]>([])
  useEffect(() => {
    // MeillTODO: 未実装の模様 確認する
    request<UserHistory[]>(`/v4/users/${ikasaId}/history`).then(
      (res) => {
        setUserHistoryList(res)
      }
    )
  }, [request, ikasaId])

  return (
    <Page className={styles.page} title={T('usageDetail')}>
      {userHistoryList.map((history) => (
        <div key={history.month} className={styles.monthItem}>
          <div className={styles.month}>{history.month}</div>
          {history.data.map((item) => (
            <ProfileHistoryItem
              className={styles.item}
              key={item.id}
              item={item}
            />
          ))}
        </div>
      ))}
    </Page>
  )
}

export default ProfileHistoryPage
