import { v4 as uuidv4 } from 'uuid'
import { getStorageItem, setStorageItem } from './storage'

const PROCESS_ID_KEY = 'currentProcessId'

/**
 * プロセスIDを生成し、LocalStorageに保存する
 * @returns 生成されたプロセスID
 */
export function generateProcessId(): string {
  const processId = uuidv4()
  setStorageItem(PROCESS_ID_KEY, processId)
  console.log('生成された processIdを記録:', processId)
  return processId
}

/**
 * LocalStorageからプロセスIDを取得する
 * 存在しない場合は新しいプロセスIDを生成して保存する
 * @returns プロセスID
 */
export function getProcessId(): string {
  const processId = getStorageItem<string>(PROCESS_ID_KEY)
  if (processId) {
    console.log('取得した processId:', processId)
    return processId
  }
  
  // プロセスIDが存在しない場合は新しく生成
console.log('プロセスIDが存在しないため、新しく生成します')
  return generateProcessId()
}

/**
 * プロセスIDを更新する
 * @param processId 新しいプロセスID
 */
export function updateProcessId(processId: string): void {
  setStorageItem(PROCESS_ID_KEY, processId)
  console.log('更新された processId:', processId)
}