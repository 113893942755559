import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import cx from 'classnames'

import { Spot } from '@/utils/spot'
import { useT } from '@/utils/language'
import { useRequest } from '@/utils/request'
import { useSetClipboardData } from '@/utils/device'
import { useNavigateLocation } from '@/utils/location'
import { useSwiper } from '@/utils/swiper'

import Page from '@/components/Page'
import Icon from '@/components/Icon'
import Menu, { MenuItem, MenuItemProps } from '@/components/Menu'

import styles from './SpotPage.module.css'

type SpotDetailProps = {
  spot: Spot
}

function SpotDetail(props: SpotDetailProps) {
  const T = useT()
  const request = useRequest()
  const setClipboardData = useSetClipboardData()
  const navigateLocation = useNavigateLocation()
  const { spot } = props
  const {
    imageUrlsList,
    spotImg,
    spotName,
    industryType,
    introduction,
    kasaMax,
  } = spot

  const [count, setCount] = useState(0)
  const getSpotStockCount = useCallback(async () => {
    const res = await request<{ count: number }>(
      // MeillTODO: /v4/spots/:id/stocksのidはscdが入る想定なのか確認したい
      `/v4/spots/${spot.scd}/stocks`
    )
    setCount(res.count)
  }, [request, spot.scd])
  useEffect(() => {
    getSpotStockCount()
  }, [getSpotStockCount])

  const menuList = useMemo<MenuItemProps[]>(() => {
    const {
      shareStartTime,
      shareEndTime,
      standPlace,
      holiday,
      timeDetail,
      address,
    } = spot

    return [
      {
        icon: 'accessTime',
        text: T('kasaShareTime'),
        hint: `${shareStartTime}~${shareEndTime}`,
      },
      {
        icon: 'beachAccess',
        text: T('kasaStandPlace'),
        hint: standPlace,
      },
      {
        icon: 'today',
        text: T('holiday'),
        hint: holiday,
      },
      {
        icon: 'timelapse',
        text: T('businessTime'),
        hint: timeDetail,
      },
      Boolean(address) && {
        icon: 'locationOn',
        text: T('address'),
        hint: (
          <div>
            {address}
            <button
              className={styles.googleMapButton}
              onClick={() => {
                navigateLocation({
                  latitude: spot.position.lat,
                  longitude: spot.position.lng,
                  name: spot.spotName,
                })
              }}
            >
              {T('googleMap')}
            </button>
          </div>
        ),
        right: (
          <button
            className={styles.copyButton}
            onClick={() => {
              setClipboardData(address)
            }}
          >
            <Icon name="copy" />
          </button>
        ),
      },
    ].filter((item) => item !== false) as MenuItemProps[]
  }, [T, spot, setClipboardData, navigateLocation])

  const { elementRef: imageContainerRef } = useSwiper({ interval: 3000 })
  const imageList = useMemo(
    () =>
      (imageUrlsList.length ? imageUrlsList : [spotImg]).filter((u) =>
        Boolean(u)
      ),
    [imageUrlsList, spotImg]
  )

  return (
    <div className={styles.container}>
      <div
        className={cx(styles.header, {
          [styles.headerWithoutImage]: imageList.length === 0,
        })}
      >
        {imageList.length > 0 && (
          <div className={styles.imageContainer} ref={imageContainerRef}>
            {imageUrlsList.map((image) => (
              <img className={styles.image} key={image} src={image} alt="" />
            ))}
          </div>
        )}
        <div className={styles.name}>{spotName}</div>
        <div className={styles.headerInfo}>
          <div className={styles.subCategory}>{industryType}</div>
          <div className={styles.count}>
            <Icon className={styles.countIcon} name="beachAccess" />
            {T('kasaCount')} {count} / {kasaMax} {T('kasaUnit')}
          </div>
        </div>
      </div>

      {introduction && (
        <div className={styles.introduction}>{introduction}</div>
      )}

      <Menu className={styles.menu}>
        {menuList.map((item, index) => (
          <MenuItem
            key={item.text}
            {...item}
            size="large"
            border={index < menuList.length - 1}
          />
        ))}
      </Menu>
    </div>
  )
}

function SpotPage() {
  const request = useRequest()

  const [spot, setSpot] = useState<Spot>()
  const { scd: scdString } = useParams<{ scd: string }>()
  const getSpot = useCallback(
    async (scd: Spot['scd']) => {
      // MeillTODO: /v4/spots/:idってscd入る想定なのか確認したい
      const spot = await request<Spot>(`/v4/spots/${scd}`)
      setSpot(spot)
    },
    [request]
  )
  useEffect(() => {
    if (!scdString) return
    getSpot(Number(scdString))
  }, [scdString, getSpot])

  if (!spot) return null

  return (
    <Page className={styles.page}>
      {/* TODO: the wrapper is for single component */}
      <SpotDetail spot={spot} />
    </Page>
  )
}

export default SpotPage
