import { getLaunchOptions } from '@chargespot/spjs'
import cx from 'classnames'
import { formatISO } from 'date-fns'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Route, Routes } from 'react-router'
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom'

import {
  ConfirmContext,
  ConfirmContextType,
  ConfirmProvider,
  useConfirm,
} from '@/utils/confirm'
import { parseStandUrl } from '@/utils/kasa'
import { useT } from '@/utils/language'
import { LoadingContext, LoadingProvider } from '@/utils/loading'
import { useLogin } from '@/utils/login'
import { useRequest } from '@/utils/request'
import {
  defaultSetting,
  Setting,
  SettingContext,
  SettingProvider,
} from '@/utils/setting'
import {
  ToastContext,
  ToastContextType,
  ToastProvider,
  useToast,
} from '@/utils/toast'
import { UIAction, UIActionContext } from '@/utils/uiAction'
import { User } from '@/utils/user'

import BottomTab, { getShowBottomTab } from '@/components/BottomTab'
import ConfirmModal from '@/components/ConfirmModal'
import LoadingModal from '@/components/LoadingModal'
import SnackBar from '@/components/SnackBar'
import UIActionModal from '@/components/UIActionModal'

import routes from '@/routes'

import '@/styles/vars.css'
import styles from './App.module.css'

function AppContent() {
  const request = useRequest()
  const T = useT()
  const { confirm: confirmState } = useContext(ConfirmContext)
  const confirm = useConfirm()
  const { uiAction } = useContext(UIActionContext)
  const { toast } = useContext(ToastContext)
  const { loading } = useContext(LoadingContext)
  const { setSetting } = useContext(SettingContext)
  const login = useLogin()
  const navigate = useNavigate()
  const showToast = useToast()

  const setUser = useCallback(async () => {
    console.log('ログイン処理を開始')
    try {
      const { ikasaId } = await login()
      console.log('ログインに成功:', { ikasaId })
      setSetting((s: Setting) => ({ ...s, ikasaId }))

      // check user status
      // MeillTODO: idかpublic_idか確認
      console.log('ユーザー情報の取得を開始:', ikasaId)
      return request<User>(`/v4/users/${ikasaId}`, {
        showToast: false,
      }).then(
        async (user) => {
          console.log('ユーザー情報の取得に成功:', user)
          
          // ユーザー情報の取得に成功しても、termsAgreedAtがnullならウォークスルー画面に遷移
          if (!user.termsAgreedAt) {
            return handleTutorialIncomplete(ikasaId, user, false)
          }

          console.log('User has agreed to terms and completed tutorial:', ikasaId)
          
          return { user, isNew: false }
        },
        async (e) => {
          // エラー情報をログ出力
          console.error('Failed to fetch user:', {
            ikasaId,
            error: e,
            url: `/v4/cs/users/${ikasaId}`,
            timestamp: new Date().toISOString(),
          })

          // エラーコードが404の場合は新規ユーザー作成
          if (e.status === 404) {
            return handleTutorialIncomplete(ikasaId, null, true)
          }
          
          // その他のエラーの場合はエラーページにリダイレクト
          setInited(true)
          navigate('/error?resource=Login&code=failed', { replace: true })
          throw e
        }
      )
    } catch (error) {
      console.error('ログイン処理に失敗:', error)
      // ログイン失敗時にエラーページにリダイレクト
      setInited(true)
      navigate('/error?resource=Login&code=failed', { replace: true })
      throw error
    }
  }, [T, confirm, request, login, setSetting, navigate])

  // チュートリアル未完了時の処理を統合
  const handleTutorialIncomplete = async (ikasaId: string, existingUser: User | null, needsUserCreation: boolean) => {
    console.log('チュートリアル未完了のため、ウォークスルー画面に遷移します')
    setInited(true)
    
    // navigate background page below confirm
    // navigate('/walkthrough', { replace: true })
    
    // agree confirm
    await confirm({
      title: T('ikasa'),
      content: (
        <>
          {T('agreementHint')}
          {'\n\n'}
          <a href="https://www.i-kasa.com/terms">{T('userAgreement')}</a>
          {'\n'}
          <a href="https://www.i-kasa.com/privacy">{T('privacyPolicy')}</a>
        </>
      ),
      cancel: null,
      confirm: T('agreementConfirm'),
    })
    
    console.log('規約に合意:', ikasaId)

    let user = existingUser
    
    // 新規ユーザー作成が必要な場合
    if (needsUserCreation) {
      // create new user
      user = await request<User>(`/v4/users`, {
        method: 'POST',
        data: { ikasa_id: ikasaId, terms_agreed_at: formatISO(new Date()) },
      })

      console.log('Created new user:', user)
    } else {
      // update existing user with terms agreement
      user = await request<User>(`/v4/users/${ikasaId}`, {
        method: 'PUT',
        data: { terms_agreed_at: formatISO(new Date()) },
      })
    }

    // register payment for user
    await request('/v4/share-spot/register-payment', {
      method: 'POST',
      data: { ikasa_id: ikasaId },
    })
    
    console.log('User tutorial incomplete handled for:', ikasaId);
    return { user, isNew: needsUserCreation }
  }

  const launchOptionsJumped = useRef(false)
  const setLaunchOptions = useCallback(async () => {
    if (launchOptionsJumped.current) return
    launchOptionsJumped.current = true

    // TODO: move it to spjs
    if (!window.nativeApp && !window.webkit) return
    const launchOption = await getLaunchOptions()

    // Scene.Scan
    if (launchOption?.scene === 1011) {
      const scanPayload = launchOption.query as { result: string }
      const standId = parseStandUrl(scanPayload.result)
      if (!standId) return

      navigate(`/choose-action/${standId}`)
      return
    }

    // Scene.Map
    if (launchOption?.scene === 1146) {
      const scd = (launchOption.query as { merchantPoiId: string })
        .merchantPoiId

      navigate(`/spot/${scd}`)
      return
    }

    // Scene.OrderDetail
    if (launchOption?.scene === 1151) {
      navigate(`/profile/history`)
      return
    }

    // Scene.UsingOrder
    if (launchOption?.scene === 2000) {
      navigate(`/profile`)
      return
    }

    // Scene.Help
    if (launchOption?.scene === 2004) {
      navigate(`/chat`)
      return
    }
  }, [navigate])

  const [inited, setInited] = useState(false)
  const init = useCallback(async () => {
    const { isNew } = await setUser()
    if (!isNew) {
      await setLaunchOptions()
    }
    setInited(true)
  }, [setUser, setLaunchOptions])

  useEffect(() => {
    init()
  }, [init])

  const location = useLocation()
  const showBottomTab = getShowBottomTab(location)

  // wating for inited
  if (!inited) return null

  return (
    <div id="app" className={styles.root}>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.name}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>

      {confirmState && (
        <ConfirmModal
          title={confirmState.title}
          content={confirmState.content}
          confirm={confirmState.confirm}
          cancel={confirmState.cancel}
          onConfirm={confirmState.onConfirm}
          onCancel={confirmState.onCancel}
        />
      )}

      {uiAction && <UIActionModal action={uiAction} />}

      {toast && (
        <SnackBar
          className={cx(styles.snackBar, {
            [styles.snackBarWithBottomTab]: showBottomTab,
          })}
          content={toast.content}
          preset={toast.preset}
        />
      )}

      {loading.length > 0 && <LoadingModal />}

      {showBottomTab && <div className={styles.bottomTabHolder}></div>}
      {showBottomTab && <BottomTab className={styles.bottomTab} />}
    </div>
  )
}

function App() {
  const [setting, setSetting] = useState<Setting>(defaultSetting)
  const [confirm, setConfirm] = useState<ConfirmContextType['confirm']>(null)
  const [toast, setToast] = useState<ToastContextType['toast']>(null)
  const [loading, setLoading] = useState<symbol[]>([])
  const [uiAction, setUIAction] = useState<UIAction | null>(null)

  return (
    <BrowserRouter>
      <HelmetProvider>
        <SettingProvider value={{ setting, setSetting }}>
          <ConfirmProvider value={{ confirm, setConfirm }}>
            <ToastProvider value={{ toast, setToast }}>
              <LoadingProvider value={{ loading, setLoading }}>
                <UIActionContext.Provider value={{ uiAction, setUIAction }}>
                  <AppContent />
                </UIActionContext.Provider>
              </LoadingProvider>
            </ToastProvider>
          </ConfirmProvider>
        </SettingProvider>
      </HelmetProvider>
    </BrowserRouter>
  )
}

export default App
