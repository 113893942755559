export enum ENV {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  MEILL = 'meill',
}

export function useEnv() {
  if (process.env.REACT_APP_ENV) {
    return process.env.REACT_APP_ENV as ENV
  }

  if (window.location.hostname === 'spot-miniapp.i-kasa.com') {
    return ENV.PRODUCTION
  }

  // show domain
  if (window.location.hostname === 'spot-miniapp-dev.i-kasa.com') {
    return ENV.DEVELOPMENT
  }

  if (window.location.hostname === 'stg-ikasa.charge-spot.com') {
    return ENV.DEVELOPMENT
  }

  return ENV.DEVELOPMENT
}

export function useApiOrigin() {
  const env = useEnv()

  if (process.env.REACT_APP_API_ORIGIN) {
    return process.env.REACT_APP_API_ORIGIN
  }

  if (env === ENV.PRODUCTION) {
    return 'https://api.i-kasa.com'
  }

  return 'https://api-dev.i-kasa.com'
}

type AuthConfig = { username: string; password: string }

function generateAuthorization({ username, password }: AuthConfig) {
  return `Basic ${window.btoa(`${username}:${password}`)}`
}

const DEV_AUTH: AuthConfig = {
  username: 'a',
  password: 'b',
}

const PRODUCTION_AUTH: AuthConfig = {
  username: 'fc24b96e-31d2-44bf-842d-182baa684e1f',
  password: '2e9ea8c6-df73-4c3b-82fb-301432cce3f2',
}

export function useApiAuthorization() {
  const env = useEnv()

  if (process.env.REACT_APP_API_AUTHORIZATION) {
    return process.env.REACT_APP_API_AUTHORIZATION
  }

  if (env === ENV.PRODUCTION) {
    return generateAuthorization(PRODUCTION_AUTH)
  }

  return generateAuthorization(DEV_AUTH)
}

const FIREBASE_DEV_CONFIG = {
  apiKey: 'AIzaSyDpsXc9K6Em4f1FGHqxU02FHYtZSTzYfPQ',
  authDomain: 'ikasa-dev-275607.firebaseapp.com',
  databaseURL: 'https://ikasa-dev-275607.firebaseio.com',
  projectId: 'ikasa-dev-275607',
  storageBucket: 'ikasa-dev-275607.appspot.com',
  messagingSenderId: '755465030740',
  appId: '1:755465030740:web:40a17afaa3a771d46ff1c1',
  measurementId: 'G-DNDDGDGL43',
}

const FIREBASE_PROD_CONFIG = {
  apiKey: 'AIzaSyBKHnECsZX6gaGYQZoU270LYZN3XMgVBXY',
  authDomain: 'ikasa-1563170568813.firebaseapp.com',
  databaseURL: 'https://ikasa-1563170568813.firebaseio.com',
  projectId: 'ikasa-1563170568813',
  storageBucket: 'ikasa-1563170568813.appspot.com',
  messagingSenderId: '549092460841',
  appId: '1:549092460841:web:8112001ff88fbcc5cc0f38',
  measurementId: 'G-3SS3MGHJ2C',
}

export function useFirebaseConfig() {
  const env = useEnv()

  if (env === ENV.PRODUCTION) {
    return FIREBASE_PROD_CONFIG
  }

  return FIREBASE_DEV_CONFIG
}

const DEV_GOOGLE_API_KEY = 'AIzaSyDpsXc9K6Em4f1FGHqxU02FHYtZSTzYfPQ'
const PROD_GOOGLE_API_KEY = 'AIzaSyBKHnECsZX6gaGYQZoU270LYZN3XMgVBXY'

export function useGoogleApiKey() {
  const env = useEnv()

  if (env === ENV.PRODUCTION) {
    return PROD_GOOGLE_API_KEY
  }

  return DEV_GOOGLE_API_KEY
}

export function useIsSpotApp() {
  return window.nativeApp || window.webkit
}
